import { useCustomer } from '@customer/composables/useCustomer'
import { CustomerRoutes } from '../types/routes'

export default defineNuxtRouteMiddleware((to) => {
  const { localePath } = useT3Utils()
  const { hasAccessTo } = useCustomerAcl()
  const { isLoggedIn } = useCustomer()

  /**
   * If user is not logged in and tries to access restricted route,
   * redirect to login page (home page with opened login drawer)
   */
  if (!isLoggedIn.value) {
    const redirectUrl = localePath(`/?auth=${to.fullPath}`)
    return navigateTo(redirectUrl)
  }

  /**
   * Check ACL Access to the current page
   * client only explanation:
   * we don't have customerSection data yet, where ACL is stored
   */
  if (import.meta.client && to.meta.access) {
    if (!hasAccessTo(to.meta.access)) {
      return navigateTo(localePath(CustomerRoutes.ACCOUNT))
    }
  }
})
